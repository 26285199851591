
import {
  computed,
  defineComponent, inject, reactive, Ref, ref,
} from 'vue';
import Dialog from 'primevue/dialog';
import InlineMessage from 'primevue/inlinemessage';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import Checkbox from 'primevue/checkbox';
// import OrderPromo from '@/components/OrderPromo.vue';
import {
  isEmail, isMinLength, isRequired, isTrue, Validation, Validator, InputStrings,
} from '@/validation';

export default defineComponent({
  name: 'Order',
  components: {
    Dialog,
    Button,
    InputText,
    InputMask,
    Checkbox,
    InlineMessage,
    // OrderPromo,
  },
  setup() {
    const isNewYear = inject('isNewYear');

    const isMobile = inject('isMobileView');
    const isOrderRequested = inject('isOrderRequested') as Ref<boolean>;
    const closeOrder = inject('closeOrder');

    const fieldErrors = ref({} as Partial<{ [x: string]: string }>);
    const fields = inject('fields') as InputStrings;
    const isPhoneCheck = inject('isPhoneCheck') as Ref<boolean>;
    const rules: Partial<{ [key: string]: Validator[] }> = {
      username: [
        isRequired('Укажите имя'),
        isMinLength(2, 'Введите верное имя'),
      ],
      phone: [isRequired('Укажите телефон')],
      email: [isRequired('Укажите email'), isEmail('Укажите верный email, пожалуйста')],
      city: [
        isRequired('Укажите ваш город'),
        isMinLength(3, 'Введите верное название города'),
      ],
      isAgreementChecked: [
        isTrue('Поставьте галочку'),
      ],
    };

    const validate = () => {
      const rulesWithPin = rules;
      if (isPhoneCheck.value) {
        rulesWithPin.pin = [isMinLength(4, 'Укажите 4 цифры')];
      }
      fieldErrors.value = new Validation(rulesWithPin).validate(fields);

      // console.log(
      //   fields,
      //   // new Validation(rules[0]).validate(fields);
      // );
      return Object.keys(fieldErrors.value).length === 0;
    };

    const saveCommonOrder = inject('saveOrder') as CallableFunction;
    const showErrors = inject('showErrors') as CallableFunction;
    const checkPhone = inject('checkPhone') as CallableFunction;

    const selectedPromo = inject('selectedPromo') as Ref;
    const isOrderSaved = ref(false);
    const saveOrder = () => {
      if (!validate()) {
        const firstKey = Object.keys(fieldErrors.value)[0];
        if (firstKey) {
          // eslint-disable-next-line no-unused-expressions
          document?.getElementById(firstKey)?.focus();
        }
        return;
      }
      const { pin, ...client } = fields;

      checkPhone({ ...client, promo: selectedPromo.value })
        .then((
          { isSaved, errors }: { isSaved: boolean, errors: string[] },
        ) => {
          if (!isSaved) {
            showErrors(errors);
          } else {
            isPhoneCheck.value = true;
            window.ym(window.ymId || 84556180, 'reachGoal', 'check_phone_rf');
          }
        });
    };

    const isMeetingBookingRequested = inject('isMeetingBookingRequested') as Ref<boolean>;
    const downloadGuide = inject('downloadGuide') as CallableFunction;
    const isGuideRequested = inject('isGuideRequested') as Ref<boolean>;
    const saveOrderWithPin = () => {
      if (!validate()) {
        const firstKey = Object.keys(fieldErrors.value)[0];
        if (firstKey) {
          // eslint-disable-next-line no-unused-expressions
          document?.getElementById(firstKey)?.focus();
        }
        return;
      }

      saveCommonOrder({ ...fields, promo: selectedPromo.value })
        .then((
          { isSaved, errors }: { isSaved: boolean, errors: string[] },
        ) => {
          if (isSaved) {
            // isOrderSaved.value = true;
            isPhoneCheck.value = false;
            isMeetingBookingRequested.value = true;
            isOrderRequested.value = false;
            if (isGuideRequested.value) {
              downloadGuide();
              isGuideRequested.value = false;
            }
            window.ym(window.ymId || 84556180, 'reachGoal', 'lead_rf');
          } else {
            fields.pin = '';
            showErrors(errors);
          }
        });
    };

    const userNameInput = ref();
    // onActivated
    // watchEffect(() => {
    //   if (isOrderRequested?.value && userNameInput.value) {
    //     userNameInput.value.$el.focus();
    //   }
    // });
    const isAgreementChecked = ref(false);

    const bookMeeting = inject('bookMeeting');
    const dialogHeader = computed(() => (isGuideRequested.value ? 'Скачать гайд "Первые шаги в туризме"' : 'Заявка на вступление в сеть'));
    const saveButtonText = computed(() => (isGuideRequested.value ? 'Скачать гайд' : 'Отправить'));
    return {
      fields,
      fieldErrors,
      validate,
      isMobile,
      isOrderRequested,
      isPhoneCheck,
      isNewYear,
      closeOrder,
      saveOrder,
      saveOrderWithPin,
      isOrderSaved,
      isAgreementChecked,
      userNameInput,
      bookMeeting,
      isGuideRequested,
      dialogHeader,
      saveButtonText,
    };
  },
});
