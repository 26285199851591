
// import { garland } from '@/svg-d';
import {
  defineComponent, ref, inject, onMounted, reactive, toRefs, Ref,
} from 'vue';
import { gsap } from 'gsap';
import { cssVar } from '@/api';
import Button from 'primevue/button';
import Chip from 'primevue/chip';
import SvgBackground from '@/components/SvgBackground.vue';
import { Waypoint } from 'vue-waypoint';

export default defineComponent({
  name: 'SpecialOffer',
  components: {
    SvgBackground,
    Button,
    Waypoint,
    Chip,
  },
  setup() {
    const isMoreVisible = ref(false);
    let waypointChange;
    const proceedToOrder = inject('proceedToOrder');
    const isMobile = inject('isMobileView');

    const flower = ref() as Ref<SVGElement>;
    const reactiveFlowers = reactive({
      flower1: null,
      flower2: null,
    });
    const flowers = toRefs(reactiveFlowers);
    waypointChange = (vector: string, e: { [key: string]: string }) => {
      console.log(vector, flower.value, e.going);

      if (flower.value && vector === 'bottom' && e.going === 'IN') {
        if (!flower.value.style.display) return;

        flower.value.style.display = '';
        for (let index = 2; index > 0; index -= 1) {
          const id = `flower${index}`;
          gsap.fromTo(flowers[id].value, {
            y: -1000,
          }, {
            y: 0,
            duration: 2,
            delay: index / 15,
            ease: 'power2.inOut',
          });
          gsap.fromTo(flowers[id].value, {
            fill: cssVar(`--${index === 1 ? 'indigo' : 'green'}-800`),
          }, {
            delay: index * Math.random(),
            duration: 1,
            repeat: -1,
            yoyo: true,
            fill: cssVar(`--${index === 1 ? 'indigo' : 'green'}-900`),
            ease: 'power2.inOut',
          });
        }
      }
    };
    const months = inject('months') as string[];
    const monthNum = (new Date()).getMonth();
    const month = months[monthNum];

    const seasons = inject('seasons') as string[];
    const getSeason = inject('getSeason') as (number) => number;
    const season = seasons[getSeason(monthNum)];

    const isNewYear = inject('isNewYear');

    let topgarland;
    let garland;
    let tree;
    let trees;
    let lamps;
    if (isNewYear) {
      topgarland = ref(null);
      const reactiveTrees = reactive({
        tree1: null,
        tree2: null,
        tree3: null,
        tree4: null,
        tree5: null,
      });
      trees = toRefs(reactiveTrees);
      tree = ref() as Ref<SVGElement>;
      const reactiveLamps = reactive({
        lamp1: null,
        lamp2: null,
        lamp3: null,
        lamp4: null,
        lamp5: null,
        lamp6: null,
        lamp7: null,
        lamp8: null,
      });
      lamps = toRefs(reactiveLamps);
      waypointChange = (vector: string, e: { [key: string]: string }) => {
        if (tree.value && vector === 'bottom' && e.going === 'IN') {
          if (!tree.value.style.display) return;

          tree.value.style.display = '';
          for (let index = 1; index < 5; index += 1) {
            const id = `tree${index}`;
            gsap.fromTo(trees[id].value, {
              y: -1000,
            }, {
              y: 0,
              duration: 2,
              delay: index / 15,
              ease: 'power2.inOut',
            });
            gsap.fromTo(trees[id].value, {
              fill: cssVar('--green-800'),
            }, {
              delay: index * Math.random(),
              duration: 1,
              repeat: -1,
              yoyo: true,
              fill: cssVar('--green-900'),
              ease: 'power2.inOut',
            });
          }
          gsap.fromTo(trees.tree5.value, {
            y: 1000,
          }, {
            y: 0,
            duration: 2,
            ease: 'power2.inOut',
          });
          gsap.fromTo(trees.tree5.value, {
            fill: cssVar('--green-800'),
          }, {
            delay: 1,
            duration: 1,
            repeat: -1,
            yoyo: true,
            fill: cssVar('--green-900'),
            ease: 'power2.inOut',
          });
          for (let index = 1; index <= 8; index += 1) {
            const id = `lamp${index}`;
            gsap.fromTo(lamps[id].value, {
              y: -1000,
            }, {
              y: 0,
              delay: 1.5,
              ease: 'power2.inOut',
            });
            gsap.fromTo(lamps[id].value, {
              fill: cssVar('--pink-300'),
            }, {
              delay: index / 8,
              duration: 1,
              repeat: -1,
              yoyo: true,
              fill: cssVar('--yellow-500'),
              ease: 'power2.inOut',
            });
          }
          gsap.fromTo(trees.tree1.value, {
            fill: cssVar('--pink-300'),
          }, {
            duration: 1,
            repeat: -1,
            yoyo: true,
            fill: cssVar('--yellow-500'),
            ease: 'power2.inOut',
          });
        }
      };
      onMounted(() => {
        if (topgarland.value) {
          gsap.fromTo(topgarland.value, {
            fill: cssVar('--pink-300'),
          }, {
            duration: 1,
            repeat: -1,
            yoyo: true,
            fill: cssVar('--yellow-500'),
            ease: 'power2.inOut',
          });
        }
      });
    }

    return {
      isMoreVisible,
      proceedToOrder,
      isNewYear,
      topgarland,
      garland,
      isMobile,
      flower,
      ...flowers,
      tree,
      ...trees,
      ...lamps,
      waypointChange,
      month,
      season,
    };
  },
});
